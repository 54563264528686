.App {
  text-align: center;
  margin-top: 0.5em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cityletter {
  float:left;
  padding-inline-start:1em;
  }
  .cityletter li {
  float:left;
  list-style:none;
  }
  .city  {
    float:left;
    padding:0;
    }
    .city li {
    float:left;
    list-style:none;
    padding-left: 1em;
    }
  
  .btn-block{width:100%}

  .product-img{width:100%}

  .hmb-product-tip{
    color: rgb(17, 17, 17); padding: 20px; font-size: 14px; font-weight: bold;
  }
 
  .hmb-product-idcard-image{
    height: 100px; display: block; margin-top: 8px; border: 1px solid rgb(180, 176, 176);
  }

  .hmb-uploader-tip{

    color: #333;
    font-size: 12px;
    text-align: left;
    display: inline-block;
    width: 100%;

  }

  .hmb-van-uploader {
    width: 100%;
    height: 115px;
    border: 1px dashed #e5e5e5;
  }

  
 
  .hmb-preview-pic {
    width: auto;
    height: 115px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.hmb-van-uploader__input {
  width: 100%;
  height: 100%;
  cursor: pointer;
}